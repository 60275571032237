import React, { useContext, useEffect, useRef, useState } from "react"
import * as S from "./styled"
import GridLayout from "../../layouts/GridLayout"
import { ReactComponent as Close } from "../../assets/ic-close.svg"
import { ReactComponent as Search } from "../../assets/ic-search.svg"
import { IconButtonWithShadow } from "../styles/Buttons.styled"
import { H3, Span } from "../styles/TextStyles.styled"
import Input from "../Input"
import { graphql, useStaticQuery } from "gatsby"
import ArrowedLink from "../Links/ArrowedLink"
import ScrollLock, { TouchScrollable } from "react-scrolllock"
import SearchContext from "../../context/SearchContext"
import { useQuery } from "@apollo/client"
import ARTICLES_PER_TAG from "../../integration/datoCms/queries/articles-per-tag"
import Tag from "../Tag"
import { useQueryParam } from "gatsby-query-params"
import useDebounce from "../../hooks/useDebounce"
import { urlFor } from "../../util/content-links"

const SearchModal = ({ locale, visible, onClose }) => {
  let {
    allTags,
    allBrands,
    allLabels,
    allMostSearched,
  } = useStaticQuery(graphql`
    {
      allLabels: allDatoCmsTranslation {
        edges {
          node {
            locale
            close
            searchBySubject
            subjectSearch
            industry
            brand
            mostRead
            mostSearched
            originalId
            selected
          }
        }
      }
      allMostSearched: allDatoCmsSearch {
        edges {
          node {
            locale
            mostRead {
              __typename
              ... on DatoCmsArticle {
                title
                slug
              }
              ... on DatoCmsPage {
                slug
                seo {
                  title
                }
              }
              ... on DatoCmsNewsItem {
                slug
                title
              }
            }
            mostSearched {
              term
            }
          }
        }
      }
      allBrands: allDatoCmsBrand {
        edges {
          node {
            id
            __typename
            locale
            title
            originalId
          }
        }
      }
      allTags: allDatoCmsArticleCategory {
        edges {
          node {
            __typename
            locale
            id
            name
            excerpt
            tagType
            originalId
          }
        }
      }
    }
  `)

  const labels = useRef(allLabels.edges.find(e => e.node.locale === "nl").node)
  const mostSearched = useRef(
    allMostSearched.edges.find(e => e.node.locale === "nl").node
  )
  useEffect(() => {
    labels.current = allLabels.edges.find(
      edge => edge.node.locale === locale
    ).node
    mostSearched.current = allMostSearched.edges.find(
      edge => edge.node.locale === locale
    ).node
  })

  const [tags, setTags] = useState(allTags.edges.map(t => t.node))
  const [brands, setBrands] = useState(allBrands.edges.map(b => b.node))

  const { data } = useQuery(ARTICLES_PER_TAG(locale, [...tags, ...brands]), {
    skip: !visible,
  })
  useEffect(() => {
    if (!data) return
    ;[...tags, ...brands].forEach(
      t => (t.count = data[`tag${t.originalId}`].count)
    )
    setTags([...tags])
    setBrands([...brands])
  }, data)

  const { search } = useContext(SearchContext)

  const currentTags = useQueryParam("tags", [])
  const currentTerm = useQueryParam("term", "")

  const [internalTerm, setInternalTerm] = useState(currentTerm || "")
  useEffect(() => {
    setInternalTerm(currentTerm || "")
  }, [currentTerm])

  const debouncedTerm = useDebounce(internalTerm, 500)
  useEffect(() => {
    if (!visible || debouncedTerm === currentTerm) {
      return
    }
    search(locale, currentTags, debouncedTerm)
  }, [debouncedTerm])

  const onTagClick = t => {
    let targetTags = currentTags
    if (!Array.isArray(currentTags) && currentTags) targetTags = [currentTags]
    if (targetTags.includes(t.originalId)) {
      targetTags = targetTags.filter(tt => t.originalId !== tt)
    } else {
      targetTags = [...targetTags, t.originalId]
    }
    return search(locale, targetTags, currentTerm)
  }

  const opened = useDebounce(visible, 100)

  const [selectedType, setSelectedType] = useState(0)
  return (
    <S.Container visible={visible}>
      <S.Overlay visible={opened} onClick={onClose} />
      <ScrollLock isActive={opened} accountForScrollbars={opened} />
      <TouchScrollable>
        <S.ModalContent>
          <GridLayout>
            <S.Close onClick={onClose}>
              <IconButtonWithShadow>
                <Close />
              </IconButtonWithShadow>
              <Span>{labels.current.close}</Span>
            </S.Close>
            <S.InputContainer
              onSubmit={e => {
                e.preventDefault()
                onClose()
                search(locale, currentTags, internalTerm)
              }}
            >
              <Input
                placeholder={labels.current.searchBySubject}
                value={internalTerm}
                onChange={e => setInternalTerm(e.target.value)}
                prefix={<Search />}
              />
            </S.InputContainer>
            <S.TagTypeSelector>
              {[
                labels.current.subjectSearch,
                labels.current.industry,
                labels.current.brand,
              ].map((type, i) => (
                <S.TagType
                  key={type}
                  as="div"
                  selected={i === selectedType}
                  onClick={() => setSelectedType(i)}
                >
                  {type}
                </S.TagType>
              ))}
            </S.TagTypeSelector>
            <S.Tags
              big
              activeTags={currentTags}
              onTagClick={onTagClick}
              using={(selectedType < 2
                ? tags.filter(
                    t =>
                      t.tagType ===
                      (selectedType === 0 ? "Subject" : "Industry")
                  )
                : brands
              ).filter(x => x.locale === locale)}
            />
            <S.SelectedDesktop>
              <span>{labels.current.selected}</span>
              {[...tags, ...brands]
                .filter(t => currentTags.includes(t.originalId))
                .filter(t => t.locale === locale)
                .map(t => (
                  <Tag
                    {...t}
                    active
                    big
                    clickable
                    onClick={() => onTagClick(t)}
                  />
                ))}
            </S.SelectedDesktop>
            <S.MostSearched>
              <H3>{labels.current.mostSearched}</H3>
              {mostSearched.current.mostSearched.map(t => (
                <ArrowedLink
                  key={t.term}
                  link={{
                    url: `${urlFor(
                      "DatoCmsArticlesPage",
                      locale
                    )}/?term=${encodeURI(t.term)}`,
                    label: t.term,
                  }}
                  onClick={onClose}
                />
              ))}
            </S.MostSearched>
            <S.MostRead>
              {mostSearched.current?.mostRead?.length > 0 && (
                <>
                  <H3>{labels.current.mostRead}</H3>
                  {mostSearched.current.mostRead.map(a => (
                    <ArrowedLink
                      key={a.title || a.seo?.title}
                      link={{
                        url: urlFor(a.__typename, locale, a.slug),
                        label: a.title || a.seo?.title,
                      }}
                      onClick={onClose}
                    />
                  ))}
                </>
              )}
            </S.MostRead>
          </GridLayout>
        </S.ModalContent>
      </TouchScrollable>
    </S.Container>
  )
}

export default SearchModal
