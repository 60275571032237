import fetch from "cross-fetch"

export const subscribeNewsletter = ({ email, locale }) => {
  return fetch("/.netlify/functions/newsletter-subscribe", {
    method: "POST",
    body: JSON.stringify({
      email,
      language: locale,
    }),
  })
}
