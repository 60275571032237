import React from "react"
import {
  IconButton,
  IconButtonNoBackground,
  IconButtonWithShadow,
} from "../styles/Buttons.styled"
import { Span } from "../styles/TextStyles.styled"
import * as S from "./ArrowedLink.styled"
import { ReactComponent as Arrow } from "../../assets/arrow-right.svg"
import useMedia from "use-media"
import { size } from "../../theme/devices"
import PropTypes from "prop-types"

const ArrowedLink = ({
  ctaData,
  link: { label, url, external = false } = {},
  withShadow,
  hideLabelOnPhones,
  hideBackground = false,
  prefix,
  children,
  ctaProps,
  ...props
}) => {
  const Button = hideBackground
    ? IconButtonNoBackground
    : withShadow
    ? IconButtonWithShadow
    : IconButton
  const isTablet = useMedia({ minWidth: size.tablet })
  return (
    <div className="link-wrapper" {...props}>
      <S.Link
        preffixed={prefix}
        ctaData={ctaData}
        link={{ label, url, external }}
        {...ctaProps}
      >
        {prefix}
        <Button hideBackground={hideBackground}>
          <Arrow />
        </Button>
        {(ctaData?.label || label) && (!hideLabelOnPhones || isTablet) && (
          <Span>{ctaData?.label || label}</Span>
        )}
        {children}
      </S.Link>
    </div>
  )
}

ArrowedLink.propTypes = {
  withShadow: PropTypes.bool,
  hideLabelOnPhones: PropTypes.bool,
  href: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.node,
}

export default ArrowedLink
