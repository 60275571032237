import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fontFamily};
    transition: background-color 250ms ease-out;
  }

  .plex {
    font-family: ${({ theme }) => theme.fontFamilyAlt};
  }
  
  a {
    text-decoration: none;
    color: currentColor;
    :hover {
      text-decoration: underline;
    }
  }
  
  .ReactModal__Overlay {
   transform: translateY(-100%);
    transition: transform 350ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    transform: translateY(0);
}

.ReactModal__Overlay--before-close{
    transform: translateY(-100%);
}
  
  * {
  box-sizing: border-box;
  }
  
`
