/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import PageWrapper from "./src/components/PageWrapper"
import { ApolloProvider } from "@apollo/client"
import datoCmsGraphqlClient from "./src/integration/datoCms/client"

const apolloGqlClient = datoCmsGraphqlClient(false)

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    console.log("adding intersection-observer polyfill")
    await import(`intersection-observer`)
  }
  const isIE = !!window.MSInputMethodContext && !!document.documentMode
  if (isIE) {
    console.log("adding ie polyfills")
    await import("cross-fetch/polyfill")
    await import("custom-event-polyfill")
  }

  if (!("scrollBehavior" in document.documentElement.style)) {
    console.log("adding smooth-scroll polyfill")
    await import("smoothscroll-polyfill").then(p => p.polyfill())
  }
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <ApolloProvider client={apolloGqlClient}>
      <PageWrapper
        path={props.path}
        locale={props.pathContext.locale}
        pageContext={props.pageContext}
      >
        {element}
      </PageWrapper>
    </ApolloProvider>
  )
}
