import React, { useContext, useMemo } from "react"
import { DomainContext } from "../../context/DomainContext"
import { ThemeProvider } from "styled-components"

export const DomainAwareThemeProvider = ({ theme, children }) => {
  const domain = useContext(DomainContext)

  const augmentedTheme = useMemo(
    () => ({
      ...theme,
      primaryColor: domain.primaryColor,
      primaryOverlayTextColor:
        domain.primaryOverlayTextColor === "light" ? "#FFFFFF" : "#0B0F2F",
    }),
    [theme, domain.primaryColor]
  )

  return <ThemeProvider theme={augmentedTheme}>{children}</ThemeProvider>
}
