import styled from "styled-components"
import device from "../../theme/devices"
import { H3 } from "../styles/TextStyles.styled"
import TagSet from "../TagSet"

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
  transform: translateY(${props => (props.visible ? 0 : -102)}%);
  transition: transform 250ms ease-out;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  transition: opacity 250ms ease-out;
  transition-delay: 150ms;
  opacity: ${props => (props.visible ? 1 : 0)};

  background: rgba(11, 15, 47, 0.47);
  transform: matrix(1, 0, 0, -1, 0, 0);
`

export const ModalContent = styled.div`
  position: absolute;
  z-index: 1;
  background: white;
  width: 100%;
  overflow-y: auto;
  padding-top: 60px;
  padding-bottom: 90px;
  max-height: 100%;
  max-width: 100%;

  > div {
    grid-template-rows: auto auto 1fr auto;
  }
`

export const Close = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-row: 1;
  grid-column: 1 / 7;
  @media (${device.desktop}) {
    grid-column: 2 / 4;
  }
  > :first-child {
    margin-right: 13px;
    cursor: pointer;
  }
  > :last-child {
    font-weight: 500;
    cursor: pointer;
  }
`

export const InputContainer = styled.form`
  grid-row: 2;
  grid-column: 1 / 7;
  margin-top: 40px;

  @media (${device.desktop}) {
    grid-row: 1;
    grid-column: 5 / 12;
    margin-top: 0;
  }
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`

export const TagTypeSelector = styled.div`
  grid-row: 3;
  grid-column: 1 / 7;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  overflow-x: auto;

  @media (${device.desktop}) {
    grid-row: 2;
    grid-column: 2 / 7;
    margin-top: 75px;
  }
`

export const TagType = styled(H3)`
  margin-right: 50px;
  transition: all 250ms ease-out;
  font-weight: ${props => (props.selected ? 500 : 300)};
  border-bottom: 4px solid;
  border-color: ${props => !props.selected && "transparent"};
  cursor: pointer;
  color: inherit;
`

export const Tags = styled(TagSet)`
  grid-row: 4;
  grid-column: 1 / 7;
  margin-top: 30px;

  @media (${device.desktop}) {
    grid-row: 3;
    grid-column: 2 / 10;
  }
`

export const SelectedDesktop = styled.div`
  display: none;
  @media (${device.desktop}) {
    grid-row: 2 / 5;
    grid-column: 10 / 13;
    margin-top: 75px;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
  }

  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  > :first-child {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;

    &:last-child {
      display: none;
    }
  }
`

export const MostSearched = styled.div`
  grid-row: 5;
  grid-column: 1/ 7;
  margin-top: 50px;
  @media (${device.desktop}) {
    grid-row: 4;
    grid-column: 2 / 6;
  }

  a,
  h3,
  span {
    color: inherit;
  }
`

export const MostRead = styled(MostSearched)`
  grid-row: 6;
  grid-column: 1 / 7;
  margin-top: 25px;
  margin-bottom: 40px;
  @media (${device.desktop}) {
    grid-row: 4;
    grid-column: 6 / 10;
    margin-top: 50px;
    margin-bottom: 0;
  }
`
