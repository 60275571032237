import React from "react"
import dark from "../theme/dark"
import light from "../theme"

const defaultValue = {
  decorated: true,
  setDecorated: () => {},
  theme: light,
  setTheme: () => {},
  headerTheme: dark,
  setHeaderTheme: () => {},
  headerCollapsedTheme: null,
  setHeaderCollapsedTheme: () => {},
  fixedHeader: false,
  setFixedHeader: () => {},
  locale: "nl",
  path: "/nl",
}

const PageWrapperContext = React.createContext(defaultValue)

PageWrapperContext.default = defaultValue

export default PageWrapperContext
