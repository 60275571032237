import React from "react"
import styled from "styled-components"
import { Span } from "../styles/TextStyles.styled"
import Tag from "../Tag"

const Container = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .tag {
      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: ${props => (props.big ? 20 : 10)}px;
    }
  }

  > span {
    font-size: 12px;
    line-height: 18px;
  }
`

const TagSet = ({
  using,
  title,
  abbreviate,
  onTagClick,
  big,
  activeTags = [],
  ...props
}) => (
  <Container {...props} big={big}>
    {title && <Span>{title}</Span>}
    <div>
      {using?.map((t, i) => (
        <Tag
          {...t}
          abbreviate={abbreviate}
          selectable={onTagClick}
          onClick={() => onTagClick && onTagClick(t)}
          big={big}
          key={i}
          active={
            t.active ||
            activeTags.includes(t.id) ||
            activeTags.includes(t.originalId)
          }
        />
      ))}
    </div>
  </Container>
)

export default TagSet
