import React, { useContext, useState } from "react"
import * as S from "./styled"
import { H6, Span } from "../styles/TextStyles.styled"
import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg"
import useMedia from "use-media"
import { size } from "../../theme/devices"
import Input from "../Input"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import { urlFor } from "../../util/content-links"
import { useForm } from "react-hook-form"
import DatoCmsText from "../DatoCmsText/DatoCmsText"
import CallToAction from "../Links/CallToAction"
import { DomainContext } from "../../context/DomainContext"
import { subscribeNewsletter } from "../../integration/functions/newsletter"

const LinkSection = ({ title, children }) => {
  const isDesktop = useMedia({ minWidth: size.desktop })
  const [expanded, setExpanded] = React.useState(false)
  return (
    <S.LinkSection>
      <S.LinkSectionHeader onClick={() => setExpanded(s => !s)}>
        <H6>{title}</H6>
        <ArrowRight className={isDesktop || expanded ? "up" : null} />
      </S.LinkSectionHeader>
      <S.LinkSectionContent
        className={isDesktop || expanded ? "expanded" : null}
      >
        {children}
      </S.LinkSectionContent>
    </S.LinkSection>
  )
}

const NewsLetter = props => {
  const [formState, setFormState] = useState("")
  const onSubmit = values => {
    if (formState === "submitting") {
      return
    }
    setFormState("submitting")
    subscribeNewsletter(values)
      .then(() => setFormState("submitted"))
      .catch(() => setFormState("error"))
  }
  const { register, handleSubmit, errors } = useForm()
  return (
    <S.NewsLetter as="form" onSubmit={handleSubmit(onSubmit)} name="Newsletter">
      <span>{props.newsLetterLabel}</span>
      <div
        css={{ maxWidth: "100%", display: formState === "submitted" && "none" }}
      >
        <Input
          required
          placeholder={props.newsLetterHint}
          type="email"
          name="email"
          ref={register({ required: true })}
          suffix={
            formState !== "submitting" && (
              <button type="submit" id="suffix">
                {props.newsLetterSignUpButtonLabel}
              </button>
            )
          }
        />
      </div>
      <input type="hidden" name="locale" value={props.locale} ref={register} />
      <div
        className={"thank-you"}
        style={{
          display: formState !== "submitted" && "none",
        }}
      >
        <Span className={"mono"}>{props.newsLetterThankYouMessage}</Span>
      </div>
    </S.NewsLetter>
  )
}

const Footer = ({ locale, ...props }) => {
  const { footer, languages, countries } = useStaticQuery(graphql`
    {
      countries: allDatoCmsCountry {
        edges {
          node {
            originalId
            locale
            name
          }
        }
      }
      languages: allDatoCmsLanguage {
        edges {
          node {
            id
            locale
            name
            country {
              originalId
            }
          }
        }
      }
      footer: allDatoCmsFooter {
        edges {
          node {
            id
            locale
            backToTopLabel
            allowNewsletterSignup
            newsLetterHint
            newsLetterLabel
            newsLetterTC
            newsLetterSignUpButtonLabel
            newsLetterThankYouMessage
            links {
              __typename
              ... on DatoCmsFooterLinksSection {
                title
                links {
                  ...CTA
                }
              }
              ... on DatoCmsContact {
                email
                address
                phone
                page {
                  __typename
                  slug
                  locale
                  domain {
                    originalId
                  }
                }
              }
            }
            contactLabel
            lastRowContent {
              ... on DatoCmsFooterLink {
                id
                label
                page {
                  __typename
                  slug
                  locale
                  domain {
                    originalId
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const domainContext = useContext(DomainContext)

  const data = footer.edges.find(x => x.node.id === domainContext.footerId)
    ?.node

  if (!data) return null

  return (
    <S.Footer>
      {data.allowNewsletterSignup && <NewsLetter {...data} />}
      <S.LinksContainer>
        <S.InnerLinksContainer>
          {data.links.map((link, i) => {
            switch (link.__typename) {
              case "DatoCmsFooterLinksSection":
                return (
                  <LinkSection key={i} title={link.title}>
                    {link.links.map((l, j) => (
                      <CallToAction key={j} ctaData={l}>
                        {l.label}
                      </CallToAction>
                    ))}
                  </LinkSection>
                )
              case "DatoCmsContact":
                const contactPageUrl = urlFor(
                  link.page.__typename,
                  link.page.locale,
                  link.page.slug,
                  "",
                  domainContext,
                  link.page.domain?.originalId
                )
                const [LinkComponent, linkProps] = contactPageUrl.startsWith(
                  "/"
                )
                  ? [Link, { to: contactPageUrl }]
                  : ["a", { href: contactPageUrl, target: "_blank" }]
                return (
                  <LinkSection key={i} title={data.contactLabel}>
                    <DatoCmsText
                      as={LinkComponent}
                      {...linkProps}
                      text={link.address}
                    />

                    <a href={`mailto:${link.email}`}>{link.email}</a>
                    <span>{link.phone}</span>
                  </LinkSection>
                )
              default:
                return null
            }
          })}
        </S.InnerLinksContainer>
      </S.LinksContainer>
      <S.Legal>
        <S.OtherContainer>
          <S.LanguageSelector
            value={locale}
            onChange={e => navigate(`/${e.target.value}`)}
          >
            {languages.edges.map(edge => (
              <option key={edge.node.locale} value={edge.node.locale}>
                {
                  countries.edges.find(
                    e =>
                      e.node.originalId === edge.node.country.originalId &&
                      e.node.locale === locale
                  ).node.name
                }{" "}
                ({edge.node.name})
              </option>
            ))}
          </S.LanguageSelector>
          <S.BackToTop
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <ArrowRight />
            <span>{data.backToTopLabel}</span>
          </S.BackToTop>
        </S.OtherContainer>
        <S.Copyright>
          {data.lastRowContent?.map((c, i) => (
            <React.Fragment key={i}>
              <StaticTextPage label={c.label} {...c.page} />
              {i === 0 && <br />}
            </React.Fragment>
          ))}
        </S.Copyright>
      </S.Legal>
    </S.Footer>
  )
}

const StaticTextPage = ({ label, locale, slug, __typename }) => {
  const domain = useContext(DomainContext)
  const url = urlFor(__typename, locale, slug, "", domain)
  let [LinkComponent, linkProps] = url?.startsWith("/")
    ? [Link, { to: url }]
    : ["a", { href: url, target: "_blank" }]
  return slug ? (
    <LinkComponent {...linkProps}>{label}</LinkComponent>
  ) : (
    <span>{label}</span>
  )
}

export default Footer
