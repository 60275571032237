import styled from "styled-components"
import device from "../../theme/devices"

export const Banner = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.color};
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  transition: all 250ms ease-out;
  transform: scaleY(0);
  transform-origin: top left;
  max-height: 0;

  &.visible {
    padding: 16px 45px;
    max-height: 90px;
    transform: scaleY(1);
  }

  svg {
    position: absolute;
    right: 10px;
    top: 13px;
    cursor: pointer;

    @media (${device.tablet}) {
      right: 20px;
    }

    line {
      stroke: ${props => props.theme.color};
    }
  }
`
