import React, { useContext } from "react"
import styled from "styled-components"
import { urlFor } from "../../util/content-links"
import { graphql, Link } from "gatsby"
import { DomainContext } from "../../context/DomainContext"

const A = styled.a``

const SLink = styled(Link)``

const CallToAction = ({
  ctaData,
  link: { url, external = false } = {},
  ...props
}) => {
  const domainContext = useContext(DomainContext)
  if (ctaData && ctaData?.link.length > 0) {
    const link = ctaData.link[0]
    if (link.__typename === "DatoCmsExternalUrl") {
      url = link.url
      external = true
    } else {
      url = urlFor(
        link.page.__typename,
        link.page.locale,
        link.page.slug,
        link.sectionSlug,
        domainContext,
        link.page.domain?.originalId
      )
      external = url.startsWith("https://")
    }
  }

  if (!url) return null

  if (external) {
    return <A href={url} target="_blank" rel="noreferrer" {...props} />
  }
  return <SLink to={url} {...props} />
}

export default CallToAction

export const fragment = graphql`
  fragment CTA on DatoCmsCallToAction {
    label
    locale
    link {
      __typename
      ... on DatoCmsExternalUrl {
        url
      }
      ... on DatoCmsPageLink {
        id
        __typename
        locale
        page {
          ... on DatoCmsPage {
            slug
            locale
            domain {
              originalId
            }
          }
          ... on DatoCmsArticle {
            slug
            locale
          }
          ... on DatoCmsEvent {
            slug
            locale
          }
          ... on DatoCmsAgenda {
            locale
          }
          ... on DatoCmsArticlesPage {
            locale
          }
          ... on DatoCmsNewsPage {
            locale
          }
        }
        sectionSlug
      }
    }
  }
`
