import React from "react"
import styled from "styled-components"
import HtmlToReact, { ProcessNodeDefinitions } from "html-to-react"
import { Link } from "gatsby"

const Container = styled.div`
  a {
    text-decoration: underline;
    color: currentColor;
  }

  img {
    max-width: 100%;
  }
`

export default ({
  text,
  textNode: { internal: { mediaType } } = {
    internal: { mediaType: "text/html" },
  },
  ...props
}) => {
  const parser = HtmlToReact.Parser()
  parser.parse(text)

  const processNodeDefinitions = new ProcessNodeDefinitions(React)

  const processingInstructions = [
    {
      replaceChildren: true,
      shouldProcessNode: node => {
        return (
          node.attribs &&
          node.attribs["href"] &&
          node.attribs["href"].startsWith("/")
        )
      },
      processNode: function (node, children) {
        return <Link to={node.attribs["href"]}>{children}</Link>
      },
    },
    {
      shouldProcessNode: node => true,
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ]
  switch (mediaType) {
    case "text/html":
      return (
        <Container className="cmsTextHtml" {...props}>
          {parser.parseWithInstructions(
            text,
            () => true,
            processingInstructions
          )}
        </Container>
      )
    default:
      return mediaType
  }
}
