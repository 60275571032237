import React, { useState } from "react"
import PageWrapperContext from "../context/PageWrapperContext"
import BaseLayout from "../layouts/BaseLayout"
import griddie from "styled-griddie"
import { StyleSheetManager } from "styled-components"
import { WithDomainContext } from "../context/DomainContext"
import { Favicon } from "./Favicon"

const PageWrapper = ({ path, locale, children, pageContext }) => {
  const [decorated, setDecorated] = useState(
    PageWrapperContext.default.decorated
  )
  const [theme, setTheme] = useState(PageWrapperContext.default.theme)
  const [headerTheme, setHeaderTheme] = useState(
    PageWrapperContext.default.headerTheme
  )
  const [headerCollapsedTheme, setHeaderCollapsedTheme] = useState(
    PageWrapperContext.default.headerCollapsedTheme
  )
  const [fixedHeader, setFixedHeader] = useState(
    PageWrapperContext.default.fixedHeader
  )
  return (
    <PageWrapperContext.Provider
      value={{
        decorated: path !== "/",
        setDecorated,
        theme,
        setTheme,
        headerTheme,
        setHeaderTheme,
        headerCollapsedTheme,
        setHeaderCollapsedTheme,
        fixedHeader,
        setFixedHeader,
        locale: locale || "nl",
        path,
      }}
    >
      <WithDomainContext
        locale={locale || "nl"}
        currentDomainId={pageContext?.domain}
      >
        <Favicon />
        <StyleSheetManager stylisPlugins={[griddie]}>
          <BaseLayout>{children}</BaseLayout>
        </StyleSheetManager>
      </WithDomainContext>
    </PageWrapperContext.Provider>
  )
}

export default PageWrapper
