import React, { useContext, useEffect, useState } from "react"
import * as S from "./styled"
import { ReactComponent as Logo } from "../../assets/logo-full.svg"
import { ReactComponent as SmallLogo } from "../../assets/logo-small.svg"
import { ReactComponent as SearchIcon } from "../../assets/ic-search.svg"
import { ReactComponent as HamburgerIcon } from "../../assets/ic-hamburguer.svg"
import { ReactComponent as CloseIcon } from "../../assets/ic-close.svg"
import { ReactComponent as UserIcon } from "../../assets/ic-user.svg"
import { ThemeContext } from "styled-components"
import AlertBanner from "../AlertBanner"
import { graphql, navigate, useStaticQuery } from "gatsby"
import dark from "../../theme/dark"
import { getPadding } from "react-scrolllock/dist/utils"
import { DomainContext } from "../../context/DomainContext"
import { DomainAwareThemeProvider } from "../DomainAwareThemeProvider"

const goHome = locale => navigate(`/${locale}`)

const isMessageDismissed = id => {
  if (typeof window === "undefined") return true
  if (!localStorage) return true

  return !(localStorage.getItem("banner") || "").includes(id)
}

const setMessageDismissed = id =>
  localStorage.setItem("banner", localStorage.getItem("banner") + id)

const Header = ({
  collapsed,
  menuOpen,
  collapsedTheme,
  theme,
  onMenuClick,
  search,
  locale,
  fixed,
  onBannerSizeChange,
  ...props
}) => {
  const themeContext = useContext(ThemeContext)
  const domainContext = useContext(DomainContext)
  if (!collapsedTheme) collapsedTheme = themeContext
  if (!theme) theme = themeContext

  let { translations } = useStaticQuery(graphql`
    {
      translations: allDatoCmsTranslation {
        edges {
          node {
            locale
            menu
            close
          }
        }
      }
    }
  `)

  const banner = domainContext.data.header.banner
  const [bannerVisible, setBannerVisible] = useState(false)
  useEffect(() => {
    const visible = banner && isMessageDismissed(banner.id)
    setBannerVisible(visible)
    if (!visible) onBannerSizeChange(0)
  })
  translations = translations.edges.find(e => e.node.locale === locale)?.node

  const { isCurrentDefault: withSearch, loginUrl } = useContext(DomainContext)
  return (
    <S.Container fixed={fixed}>
      <DomainAwareThemeProvider
        theme={menuOpen ? dark : collapsed ? collapsedTheme : theme}
      >
        <S.HeaderWrapper
          className={`header-container ${collapsed && "collapsed"} `}
        >
          {banner && banner.message && !menuOpen && (
            <AlertBanner
              banner={banner}
              visible={bannerVisible}
              onClose={() => {
                setBannerVisible(false)
                setMessageDismissed(banner.id)
                onBannerSizeChange(0)
              }}
              onSizeChange={size =>
                onBannerSizeChange && onBannerSizeChange(size)
              }
            />
          )}
          <S.Nav extraPadding={!menuOpen ? 0 : getPadding()}>
            {collapsed ? (
              domainContext.data.header.logoCollapsed ? (
                <img
                  src={domainContext.data.header.logoCollapsed}
                  onClick={() => goHome(locale)}
                />
              ) : (
                <SmallLogo onClick={() => goHome(locale)} />
              )
            ) : domainContext.data.header.logo ? (
              <img
                src={domainContext.data.header.logo}
                onClick={() => goHome(locale)}
              />
            ) : (
              <Logo onClick={() => goHome(locale)} />
            )}
            <S.RightAligned>
              <S.Overflow hidden={menuOpen}>
                {loginUrl && (
                  <S.IconButton
                    as={"a"}
                    href={loginUrl}
                    target={"_blank"}
                    rel="noopener"
                  >
                    <UserIcon />
                  </S.IconButton>
                )}
                {withSearch && (
                  <S.IconButton onClick={search}>
                    <SearchIcon />
                  </S.IconButton>
                )}
              </S.Overflow>
              <S.MenuWrapper onClick={() => onMenuClick && onMenuClick()}>
                {menuOpen ? translations.close : translations.menu}
                <S.HamburgerWrapper>
                  {menuOpen ? <CloseIcon /> : <HamburgerIcon />}
                </S.HamburgerWrapper>
              </S.MenuWrapper>
            </S.RightAligned>
          </S.Nav>
        </S.HeaderWrapper>
      </DomainAwareThemeProvider>
      {props.children}
    </S.Container>
  )
}

export default Header
