import styled, { css } from "styled-components"
import device from "../../theme/devices"

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  transition: all 250ms ease-out;
  ${props =>
    props.fixed &&
    css`
      position: fixed;
      z-index: 30;
    `};
  .header-container {
    width: 100%;
    position: fixed;
    z-index: 30;
    .warning {
    }
  }
`

export const HeaderWrapper = styled.div`
  width: 100%;
  transition: all 250ms ease-out, padding 0s ease;

  &.collapsed {
    background: white;
  }
`

export const Alert = styled.div``

export const Nav = styled.nav`
  padding: 16px 10px;
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.color};
  transition: inherit;
  @media (${device.tablet}) {
    padding: 16px ${props => props.extraPadding + 20}px 16px 20px;
  }

  > :first-child {
    cursor: pointer;
  }
`

export const RightAligned = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

export const IconButton = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  line-height: 32px;
  text-align: center;
  transition: all 250ms ease-out;
  cursor: pointer;
  margin-right: 16px;
  outline: none;

  :hover {
    transform: scale(1.25);
  }
  svg {
    height: 14px;
  }
  circle {
    stroke: ${props => props.theme.color};
  }

  path {
    fill: ${props => props.theme.color};
  }
`

export const Overflow = styled.div`
  display: none;
  @media (${device.tablet}) {
    display: flex;
    transition: opacity 250ms ease-out;
    flex-direction: row;
    ${props =>
      props.hidden &&
      css`
        pointer-events: none;
        opacity: 0;
      `}
  }
`

export const MenuWrapper = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 34px;
  color: ${({ theme }) => theme.color};
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 100px;
  justify-content: flex-end;

  &:hover {
    text-decoration: underline;
  }
`

export const HamburgerWrapper = styled.div`
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  background: white;
  border-radius: 50%;
  margin-left: 10px;
`
