import styled from "styled-components"

export const WrapperAlt = styled.div`
  background: #ffffff;
  border: 0.5px solid #0b0f2f;
  box-sizing: border-box;
  border-radius: 2px;
  color: ${({ theme }) => theme.color};
  display: inline-flex;
  max-width: 100%;
  transition: all 0.15s ease-out;
  width: 100%;

  input,
  textarea {
    color: inherit;
    background: none;
    outline: none;
    border: none;
    flex: 1;
    overflow-x: hidden;
    min-height: 40px;
    padding: 4px 12px;
    resize: none;
  }

  textarea {
    min-height: 235px;
  }
`

export const Wrapper = styled.div`
  color: inherit;
  display: inline-flex;
  border-bottom: 4px solid;
  max-width: 100%;
  transition: all 0.15s ease-out;
  width: 100%;

  &.focused {
    > :not(input) {
      color: ${({ theme }) => theme.primaryColor};
    }

    border-color: ${({ theme }) => theme.primaryColor};
  }

  input {
    color: inherit;
    border: none;
    outline: none;
    background: none;
    background: transparent;
    padding-bottom: 12px;
    flex: 1;
    overflow: hidden;

    :invalid {
      box-shadow: none;
    }

    :focus {
    }
  }
`

export const Prefix = styled.div`
  padding: 0 6px 10px;
  color: inherit;
`

export const Suffix = styled.div`
  * {
    transition: color 250ms ease-out;
    color: inherit;
  }
`
