import styled from "styled-components"
import device from "../../theme/devices"

export const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.color};
  transform: translateY(-100vh);
  transition: transform 250ms ease-in-out, opacity 500ms ease-out;
  z-index: 20;
  padding: 140px 10px 10px 10px;
  overflow-y: auto;

  @media (${device.tablet}) {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }

  @media (${device.desktop}) {
    padding-top: 90px;
  }

  &.open {
    transform: none;
  }
`

export const Links = styled.div`
  grid-row: 1;
  grid-column: 1 / 7;

  display: flex;
  flex-direction: column;

  @media (${device.desktop}) {
    grid-column: 3 / 7;
  }
  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const LinkMenu = styled.div`
  > a,
  .entry-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
  }

  .sub {
    transition: max-height 250ms ease-out;
    max-height: 0;
    overflow: hidden;

    &.expanded {
      max-height: 300px;
    }
  }

  @media (${device.desktop}) {
    > a,
    .entry-title {
      font-weight: 600;
      font-size: 50px;
      line-height: 70px;
    }

    .sub.expanded {
      max-height: 0;
    }

    &:hover {
      .sub {
        max-height: 300px;
      }
    }
  }
`

export const Actions = styled.div`
  grid-row: 2;
  grid-column: 1 / 7;
  margin-top: 60px;

  > :not(:first-child) {
    margin-top: 20px;
  }

  .link-wrapper:hover span {
    text-decoration: underline;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  @media (${device.desktop}) {
    grid-column: 3 / 7;
    margin-top: 120px;
    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
    }
    > :not(:first-child) {
      margin-top: 30px;
    }
  }
`

export const LanguageSelector = styled.div`
  grid-row: 3;
  grid-column: 1 / 7;
  font-size: 14px;
  line-height: 21px;
  margin-top: 30px;

  @media (${device.desktop}) {
    grid-row: 2;
    grid-column: 9 / 12;
    margin-top: 120px;
    font-size: 18px;
    line-height: 27px;
  }
`

export const Address = styled.div`
  grid-row: 4;
  grid-column: 1 / 7;
  font-size: 14px;
  line-height: 21px;
  margin-top: 30px;
  padding-bottom: 30px;

  h3 {
    display: none;
  }
  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (${device.desktop}) {
    margin-top: 0;
    grid-row: 1;
    grid-column: 9 / 12;
    font-size: 18px;
    line-height: 27px;

    h3 {
      display: block;
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;
    }
  }
`
