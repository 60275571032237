import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const DomainContext = React.createContext({
  isCurrentDefault: false,
  domainId: "",
  data: { header: { logo: "", logoCollapsed: "", banner: undefined } },
  others: [{ id: "", url: "" }],
  defaultDomainUrl: "",
  menuId: "",
  footerId: "",
  loginUrl: "",
  primaryColor: "#ffce01",
  loadingScreenImage: undefined,
  favicon: {
    url: "",
    mimeType: "",
  },
})

export const WithDomainContext = ({ children, currentDomainId, locale }) => {
  const domains = useStaticQuery(graphql`
    {
      allDomains: allDatoCmsDomain {
        edges {
          node {
            originalId
            isDefaultDomain
            sitePrimaryColor {
              hex
            }
            primaryOverlayTextColor
            loadingScreenImage {
              url
              fluid(maxWidth: 1440, imgixParams: { auto: "compress" }) {
                ...GatsbyDatoCmsFluid
              }
            }
            favicon {
              url
              mimeType
            }
            url
            locale
            banner {
              locale
              message
              id
            }
            headerLogo {
              url
            }
            headerLogoCollapsed {
              url
            }
            showLoginButton
            loginUrl
            menu {
              id
            }
            footer {
              id
            }
          }
        }
      }
    }
  `)
    .allDomains.edges.map(e => e.node)
    .filter(n => n.locale === locale)

  const defaultDomain = domains.find(d => d.isDefaultDomain)

  const currentDomain = currentDomainId
    ? domains.find(d => d.originalId === currentDomainId)
    : defaultDomain

  return (
    <DomainContext.Provider
      value={{
        data: {
          header: {
            logo: currentDomain.headerLogo?.url,
            logoCollapsed: currentDomain.headerLogoCollapsed?.url,
            banner: currentDomain.banner,
          },
        },
        favicon: currentDomain.favicon,
        primaryOverlayTextColor: currentDomain.primaryOverlayTextColor,
        primaryColor: currentDomain.sitePrimaryColor.hex,
        loadingScreenImage: currentDomain.loadingScreenImage,
        isCurrentDefault: !!currentDomain.isDefaultDomain,
        domainId: currentDomain.originalId,
        defaultDomainUrl: defaultDomain.url,
        others: domains.map(d => ({ id: d.originalId, url: d.url })),
        menuId: currentDomain.menu.id,
        footerId: currentDomain.footer.id,
        loginUrl: currentDomain.showLoginButton && currentDomain.loginUrl,
      }}
    >
      {children}
    </DomainContext.Provider>
  )
}
