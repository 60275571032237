import React, { useState } from "react"
import * as S from "./styled"

const Input = ({
  prefix,
  reference,
  suffix,
  altStyle,
  textArea,
  children,
  ...props
}) => {
  const [focused, setFocused] = useState(false)
  const WrapperComponent = altStyle ? S.WrapperAlt : S.Wrapper
  return (
    <div
      className="input-wrapper"
      style={{ display: "block", position: "relative" }}
    >
      <WrapperComponent className={`${focused ? "focused" : ""}`}>
        {prefix && <S.Prefix>{prefix}</S.Prefix>}
        {textArea ? (
          <textarea
            ref={reference}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            {...props}
          />
        ) : (
          <input
            ref={reference}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            {...props}
          />
        )}
        {suffix && <S.Suffix>{suffix}</S.Suffix>}
        {children}
      </WrapperComponent>
    </div>
  )
}

export default React.forwardRef((props, ref) => (
  <Input reference={ref} {...props} />
))
