// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-agenda-jsx": () => import("./../../../src/templates/agenda.jsx" /* webpackChunkName: "component---src-templates-agenda-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-articles-overview-jsx": () => import("./../../../src/templates/articles-overview.jsx" /* webpackChunkName: "component---src-templates-articles-overview-jsx" */),
  "component---src-templates-generic-jsx": () => import("./../../../src/templates/generic.jsx" /* webpackChunkName: "component---src-templates-generic-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-job-opening-jsx": () => import("./../../../src/templates/job-opening.jsx" /* webpackChunkName: "component---src-templates-job-opening-jsx" */),
  "component---src-templates-news-article-jsx": () => import("./../../../src/templates/news-article.jsx" /* webpackChunkName: "component---src-templates-news-article-jsx" */),
  "component---src-templates-news-overview-jsx": () => import("./../../../src/templates/news-overview.jsx" /* webpackChunkName: "component---src-templates-news-overview-jsx" */),
  "component---src-templates-team-member-jsx": () => import("./../../../src/templates/team-member.jsx" /* webpackChunkName: "component---src-templates-team-member-jsx" */)
}

