import React, { useCallback, useContext, useMemo, useState } from "react"
import * as S from "./styled"
import dark from "../../theme/dark"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import ArrowedLink from "../Links/ArrowedLink"
import Dropdown from "../Dropdown"
import GridLayout from "../../layouts/GridLayout"
import DatoCmsText from "../DatoCmsText/DatoCmsText"
import { urlFor } from "../../util/content-links"
import { TouchScrollable } from "react-scrolllock"
import CallToAction from "../Links/CallToAction"
import useMedia from "use-media"
import { size } from "../../theme/devices"
import { DomainContext } from "../../context/DomainContext"
import { DomainAwareThemeProvider } from "../DomainAwareThemeProvider"

const MenuLink = ({ __typename, title, link, links, isDesktop, onClick }) => {
  const [expanded, setExpanded] = useState(false)

  const ctaProps = useMemo(() => ({ onClick }), [onClick])
  return (
    <S.LinkMenu>
      {link ? (
        <CallToAction
          ctaData={link}
          className="entry-title"
          onClick={e => {
            if (!isDesktop && links?.length > 0) {
              e.preventDefault() // Only on mobile
              setExpanded(e => !e)
            } else {
              onClick()
            }
          }}
        >
          {title}
        </CallToAction>
      ) : (
        <a className="entry-title" onClick={() => setExpanded(e => !e)}>
          {title}
        </a>
      )}

      {links && links.length > 0 && (
        <div className={`sub ${expanded && "expanded"}`}>
          {!isDesktop && link && (
            <ArrowedLink hideBackground ctaData={link} ctaProps={ctaProps} />
          )}
          {links.map((l, i) => (
            <ArrowedLink
              hideBackground
              key={i}
              ctaData={l}
              ctaProps={ctaProps}
            />
          ))}
        </div>
      )}
    </S.LinkMenu>
  )
}

const Menu = ({ open = false, setOpen, locale = "nl" }) => {
  const {
    allDatoCmsMenu: { edges },
    languages,
  } = useStaticQuery(graphql`
    {
      languages: allDatoCmsLanguage {
        edges {
          node {
            id
            locale
            name
          }
        }
      }

      allDatoCmsMenu {
        edges {
          node {
            id
            locale
            links {
              __typename
              ... on DatoCmsSubmenu {
                id
                title
                link {
                  ...CTA
                }
                links {
                  ...CTA
                }
              }
            }
            actions {
              title
              url
            }
            contactLabel
            contact {
              id
              phone
              email
              location
              address
              page {
                __typename
                slug
                domain {
                  originalId
                }
              }
              addressNode {
                internal {
                  mediaType
                }
              }
            }
          }
        }
      }
    }
  `)
  const { menuId } = useContext(DomainContext)

  const close = useCallback(() => setOpen(false), [setOpen])

  const menuData = edges.find(x => x.node.id === menuId).node

  const isDesktop = useMedia({ minWidth: size.desktop })
  return (
    <DomainAwareThemeProvider theme={dark}>
      <TouchScrollable>
        <S.Container
          as="nav"
          className={open ? "open" : ""}
          aria-hidden={!open}
          role="menu"
        >
          <GridLayout>
            <S.Links>
              {menuData.links.map(link => (
                <MenuLink
                  key={link.id}
                  {...link}
                  isDesktop={isDesktop}
                  onClick={close}
                />
              ))}
            </S.Links>
            <S.Actions>
              {menuData.actions.map(action => (
                <ArrowedLink
                  key={action.url}
                  link={{
                    url: action.url,
                    label: action.title,
                    external: true,
                  }}
                />
              ))}
            </S.Actions>
            <S.LanguageSelector>
              <Dropdown
                value={locale}
                onChange={e => navigate(`/${e.target.value}`)}
              >
                {languages.edges.map(e => (
                  <option key={e.node.locale} value={e.node.locale}>
                    {e.node.name}
                  </option>
                ))}
              </Dropdown>
            </S.LanguageSelector>
            <Contact
              contact={menuData.contact}
              locale={locale}
              contactLabel={menuData.contactLabel}
            />
          </GridLayout>
        </S.Container>
      </TouchScrollable>
    </DomainAwareThemeProvider>
  )
}

const Contact = ({ contact, locale, contactLabel }) => {
  const domainContext = useContext(DomainContext)
  const contactPageUrl = urlFor(
    contact.page.__typename,
    locale,
    contact.page.slug,
    "",
    domainContext,
    contact.page.domain?.originalId
  )

  let [LinkComponent, linkProps] = contactPageUrl.startsWith("/")
    ? [Link, { to: contactPageUrl }]
    : ["a", { href: contactPageUrl, target: "_blank" }]

  return (
    <S.Address>
      <h3>{contactLabel}</h3>
      <LinkComponent {...linkProps}>
        <DatoCmsText textNode={contact.addressNode} text={contact.address} />
      </LinkComponent>
      <br />
      <a href={`mailto:${contact.email}`}>{contact.email}</a>
      <br />
      <span>{contact.phone}</span>
    </S.Address>
  )
}

export default Menu
