import styled, { css } from "styled-components"
import CallToAction from "./CallToAction"

export const Link = styled(CallToAction)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  outline: none;
  animation: all 250ms ease-out;

  &:hover {
    button {
      transform: translateX(7px);
    }

    span {
      text-decoration: underline;
    }
  }

  button {
    align-self: start;
  }

  ${props =>
    props.preffixed &&
    css`
      display: flex;
      > :nth-child(2) {
        margin-left: auto;
      }
    `}

  :hover {
    > * {
      cursor: pointer;
    }
  }

  > :last-child:not(:first-child) {
    margin-left: 10px;
  }

  span {
    font-weight: 500;
  }
`
