import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"

const datoCmsGraphqlClient = ssr =>
  new ApolloClient({
    dataIdFromObject: o => o.id,
    link: new HttpLink({
      uri: "/.netlify/functions/query",
      fetch: fetch,
      headers: {
        Accept: "application/json",
      },
    }),
    ssrMode: ssr,
    cache: ssr
      ? new InMemoryCache()
      : new InMemoryCache().restore(window.__APOLLO_STATE__),
  })

export default datoCmsGraphqlClient
