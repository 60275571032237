module.exports.urlFor = (
  __typename,
  locale,
  slug = "",
  fragment = "",
  domainContext = null,
  pageDomainId = null
) => {
  let prefix = ""
  if (domainContext) {
    if (!domainContext.isCurrentDefault && !pageDomainId) {
      prefix = domainContext.defaultDomainUrl
    } else if (pageDomainId && pageDomainId !== domainContext.domainId) {
      prefix = domainContext.others.find(o => o.id === pageDomainId).url
    }

    if (prefix.endsWith("/")) {
      prefix = prefix.slice(0, prefix.length - 1)
    }
  }

  switch (__typename) {
    case "DatoCmsPage":
      return `${prefix}/${locale}/${slug}${fragment ? `#${fragment}` : ""}`
    case "DatoCmsArticlesPage":
      return `${prefix}/${locale}/articles`
    case "DatoCmsArticle":
    case "ArticleRecord":
      return `${prefix}/${locale}/articles/${slug}`
    case "DatoCmsContactPage":
      return `${prefix}/${locale}/contact`
    case "DatoCmsAgenda":
      return `${prefix}/${locale}/agenda`
    case "DatoCmsEvent":
      return `${prefix}/${locale}/agenda/${slug}`
    case "DatoCmsJobOffer":
      return `${prefix}/${locale}/job/${slug}`
    case "DatoCmsTargetGroup":
      return `${prefix}/${locale}/articles/?targetGroup=${slug}`
    case "DatoCmsTeamMember":
      return `${prefix}/${locale}/team/${slug}`
    case "DatoCmsNewsPage":
      return `${prefix}/${locale}/news/${slug}`
    case "DatoCmsNewsItem":
    case "NewsItemRecord":
      return `${prefix}/${locale}/news/${slug}`
    default:
      return __typename
  }
}
