import React from "react"
import styled, { css } from "styled-components"
import { Span } from "../styles/TextStyles.styled"

const Container = styled.div`
  display: block;
  background: ${props => (props.active ? props.color : "#fafaf5")};
  border-radius: 20px;
  padding: 5px 10px;
  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
      transition: all 250ms ease-out;
      :hover {
        background: ${props => (props.active ? "#909090" : "#f0f0f0")};
        text-decoration: underline;
      }
    `};

  ${props =>
    props.big &&
    css`
      && {
        padding: 12px 25px;
        border-radius: 23px;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
      }
    `}

  span {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: ${props => (props.active ? "white" : props.color)};
  }
`

const colorFor = ({ __typename, tagType }) => {
  switch (__typename) {
    case "BrandRecord":
    case "DatoCmsBrand":
      return "#70876E"
    case "ArticleCategoryRecord":
    case "DatoCmsArticleCategory":
      return tagType === "Subject" ? "#336282" : "#5885A4"
    default:
      return null
  }
}

const Tag = ({ id, excerpt, abbreviate, active, count, ...props }) => (
  <Container
    className="tag"
    color={colorFor(props)}
    clickable={props.onClick}
    active={active}
    {...props}
  >
    <Span css={{ color: colorFor(props), whiteSpace: "nowrap" }}>
      {(abbreviate && (props.shortName || excerpt)) ||
        props.name ||
        props.title}
      {count > 0 && <sup>{count}</sup>}
    </Span>
  </Container>
)

export default Tag
