import React from "react"
import { urlFor } from "../util/content-links"
import { navigate } from "gatsby"
import queryString from "query-string"

const defaultValue = {
  openSearchModal: () => {},
  search: (locale, tags, term, page = undefined) => {
    return navigate(
      `${urlFor("DatoCmsArticlesPage", locale)}?${queryString.stringify({
        tags,
        term,
        page,
      })}`,
      {}
    )
  },
}

const SearchContext = React.createContext(defaultValue)

SearchContext.defaultValue = defaultValue

export default SearchContext
