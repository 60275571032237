export default {
  fontFamily: "Poppins, sans-serif",
  fontFamilyAlt: "'IBM Plex Mono', monospace",
  color: "#0B0F2F",
  backgroundColor: "#FFFFFF",
  backgroundColor2: "#F3F3F3",
  backgroundColor3: "#FDFEFF",
  primaryColor: "#ffce01",
  secondaryColor: "#5885A4",
  primaryOverlayTextColor: "#0B0F2F",
}
