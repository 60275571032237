import { gql } from "@apollo/client"

const ARTICLES_PER_TAG = (locale, tags) => {
  tags = tags.map(
    tag => `
  tag${tag.originalId}: _allArticlesMeta(locale: ${locale}, filter: {title: {neq: null}, categories: {anyIn: [${tag.originalId}]}}) {
    count
  }`
  )
  return gql`{
      ${tags.join("\n")}
  }`
}

export default ARTICLES_PER_TAG
