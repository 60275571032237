import styled, { css } from "styled-components"
import device from "../../theme/devices"

const textColor = css`
  color: ${({ theme }) => theme.color};
`

const monoVariant = css`
  &.mono {
    font-family: ${props => props.theme.fontFamilyAlt};
  }
`

export const Colored = styled.span`
  ${textColor}
`

export const H1 = styled.h1`
  ${textColor};
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 26px;

  @media (${device.tablet}) {
    font-weight: bold;
    font-size: 40px;
    line-height: 46px;
  }

  ${monoVariant}
`

export const H2 = styled.h2`
  ${textColor};
  ${monoVariant}
`

export const H3 = styled.h3`
  ${textColor};
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  ${monoVariant}
`

export const H4 = styled.h4`
  ${textColor};
  ${monoVariant}
`

export const H5 = styled.h5`
  ${textColor};
  ${monoVariant}
`

export const H6 = styled.h6`
  ${textColor};
  ${monoVariant};
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
`

const TextSpan = css`
  ${textColor};
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  ${monoVariant}
`

export const SpanAsDiv = styled.div`
  ${TextSpan};
`

export const Span = styled.span`
  ${TextSpan};
`

const paragraph = css`
  ${textColor};
  ${monoVariant};
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  &.mono {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
  }
`

export const P = styled.p`
  ${paragraph};
`

export const PAsDiv = styled.div`
  ${paragraph};
`

export const SubTitle = styled.span`
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  ${textColor};
  ${monoVariant}
`

export const ArticleTitle = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;

  @media (${device.desktop}) {
    font-size: 34px;
    line-height: 51px;
  }

  ${textColor};
`

export const AgendaTitle = styled.h3`
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
`
