import React, { useEffect } from "react"
import dark from "../../theme/dark"
import * as S from "./styled"
import { ReactComponent as Close } from "../../assets/ic-close.svg"
import { DomainAwareThemeProvider } from "../DomainAwareThemeProvider"

const AlertBanner = ({
  banner,
  onClose,
  visible,
  onSizeChange = size => console.log(size),
}) => {
  const containerRef = React.useRef(null)

  const onResize = () =>
    visible &&
    onSizeChange(containerRef.current?.getBoundingClientRect().height)
  useEffect(() => {
    window.addEventListener("resize", onResize)
    setTimeout(onResize, 250)
    setTimeout(onResize, 500)
    return () => window.removeEventListener("resize", onResize)
  })
  return (
    <DomainAwareThemeProvider theme={dark}>
      <S.Banner ref={containerRef} className={visible ? "visible" : null}>
        {banner.message}
        <Close onClick={() => onClose && onClose()} />
      </S.Banner>
    </DomainAwareThemeProvider>
  )
}

export default AlertBanner
