import styled from "styled-components"
import PropTypes from "prop-types"
import device from "../theme/devices"
import constants from "../theme/constants"

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 5px;
  padding-left: ${props => !props.noMarginLeft && constants.margin10px};
  padding-right: ${props => !props.noMarginRight && constants.margin10px};
  padding-top: ${props => props.padTop && "75px"};
  padding-bottom: ${props => props.padBottom && "75px"};

  @media (${device.tablet}) {
    padding-left: ${props => !props.noMarginLeft && constants.margin20px};
    padding-right: ${props => !props.noMarginRight && constants.margin20px};
  }

  @media (${device.desktop}) {
    padding-left: ${props => !props.noMarginLeft && constants.margin10px};
    padding-right: ${props => !props.noMarginRight && constants.margin10px};
    padding-top: ${props => props.padTop && "90px"};
    padding-bottom: ${props => props.padBottom && "90px"};
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 10px;
    max-width: 1440px;
    margin: 0 auto;
  }
`

GridLayout.propTypes = {
  noMarginLeft: PropTypes.bool,
  noMarginRight: PropTypes.bool,
  padTop: PropTypes.bool,
  padBottom: PropTypes.bool,
}

GridLayout.WideItem = styled.div`
  grid-column: 1 / 7;
  @media (${device.desktop}) {
    grid-column: 2 / 13;
  }
`

export default GridLayout
