import React from "react"
import styled from "styled-components"
import { ReactComponent as Arrow } from "../../assets/arrow-right.svg"
import device from "../../theme/devices"

const Wrapper = styled.div`
  position: relative;
  text-decoration: none;

  &:hover {
    text-decoration: underline;

    svg {
      transform: rotate(90deg) scale(1.01);
    }
  }
`

const ArrowContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  svg {
    transform: rotate(90deg);
    margin-top: 23px;
    path {
      fill: currentColor;
    }
  }
`

const Select = styled.select`
  width: 100%;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 2px;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  position: relative;

  transition: all 250ms ease-out;
  padding: 14px 40px 13px 13px;
  @media (${device.desktop}) {
    padding: 15px 40px 17px 19px;
  }

  > option {
    background: transparent;
    color: initial;
  }
`

const Dropdown = ({ value, onChange, dropdownRef, children }) => (
  <Wrapper className="dropdown-wrapper">
    <ArrowContainer>
      <Arrow />
    </ArrowContainer>
    <Select value={value} onChange={onChange} ref={dropdownRef}>
      {children}
    </Select>
  </Wrapper>
)

export default Dropdown
