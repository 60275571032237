import React, { useContext, useMemo } from "react"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { DomainContext } from "../../context/DomainContext"

const sizedFaviconUrl = (faviconUrl, width, height) =>
  `${faviconUrl}?w=${width}&h=${height || width}`

export const Favicon = () => {
  const {
    favicon: { url, mimeType },
  } = useContext(DomainContext)
  const faviconMetaTags = useMemo(
    () => ({
      tags: [
        ...["57", "60", "72", "76", "114", "120", "144", "152", "182"].map(
          size => ({
            tagName: "link",
            attributes: {
              rel: "apple-touch-icon",
              sizes: `${size}x${size}`,
              href: sizedFaviconUrl(url, size),
            },
          })
        ),
        ...[
          [70, 70],
          [150, 150],
          [310, 310],
          [310, 150],
        ].map(([w, h]) => ({
          tagName: "meta",
          attributes: {
            name: `msapplication-square${w}x${h}`,
            content: sizedFaviconUrl(url, w, h),
          },
        })),
        ...[16, 32, 96, 192].map(size => ({
          tagName: "link",
          attributes: {
            rel: "icon",
            sizes: `${size}x${size}`,
            href: sizedFaviconUrl(url, size),
            type: mimeType.endsWith("+xml") ? mimeType.slice(0, -4) : mimeType,
          },
        })),
      ],
    }),
    [url, mimeType]
  )

  return <HelmetDatoCms favicon={faviconMetaTags} />
}
