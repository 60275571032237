import styled, { css } from "styled-components"
import device from "../../theme/devices"
import GridLayout from "../../layouts/GridLayout"

export const Footer = styled.footer`
  max-width: 100%;
  padding-top: 90px;
  padding-bottom: 100px;
  color: ${props => props.theme.color};
  @media (${device.desktop}) {
    padding-top: 170px;
  }
`

export const NewsLetter = styled(GridLayout)`
  padding-bottom: 65px;
  input::placeholder {
    color: inherit;
    opacity: 30%;
  }
  :not(.checkbox-wrapper) > span {
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    grid-row: 1;
    grid-column: 1 / 7;
    padding-right: 20px;

    @media (${device.desktop}) {
      grid-column: 2 / 6;
    }
  }

  > div {
    grid-row: 2;
    grid-column: 1 / 7;
    margin-top: 30px;
    @media (${device.desktop}) {
      grid-row: 1;
      grid-column: 6 / 12;
      margin-top: 0;
    }
  }

  .checkbox-wrapper {
    margin-top: 20px;
    div {
      p {
        margin-top: 0;
      }
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    &:hover {
      a {
        text-decoration: underline;
      }
    }
  }

  .input-wrapper {
    @media (${device.tablet}) {
      margin-top: 35px;
    }

    @media (${device.desktop}) {
      margin-top: 0;
    }

    button {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      cursor: pointer;
      background: transparent;
      outline: none;
      border: none;
      height: 100%;
      padding-bottom: 10px;
    }
  }

  .thank-you {
    span {
      display: block;
      max-width: 300px;
      @media (${device.desktop}) {
        margin-left: auto;
        max-width: 250px;
      }
    }
  }
`

export const LinksContainer = styled(GridLayout)``

export const InnerLinksContainer = styled.div`
  grid-column: 1 / 7;
  display: flex;
  flex-direction: column;
  @media (${device.desktop}) {
    grid-column: 2 / 12;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const LinkSection = styled.div`
  margin-bottom: 21px;
  white-space: pre-line;
  @media (${device.desktop}) {
    :not(:last-child) {
      padding-right: 30px;
    }
  }
`

export const LinkSectionContent = styled.div`
  margin-top: 20px;
  max-height: 0;
  overflow: hidden;
  transition: all 250ms ease-out;
  a,
  span,
  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    display: block;
    color: inherit;
    text-decoration: none;
  }

  a p:first-child {
    margin: 0;
  }

  a:hover {
    text-decoration: underline;
  }

  &.expanded {
    max-height: 250px;
  }
`

export const LinkSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;

  h6 {
    margin: 0;
    color: inherit;
  }

  svg {
    transition: all 250ms ease-in-out;
    transform: rotate(90deg);

    &.up {
      transform: rotate(-90deg);
    }
  }

  @media (${device.desktop}) {
    svg {
      display: none;
    }
  }
`

export const Legal = styled(GridLayout)`
  font-weight: 300;
  font-size: 12px;
  line-height: 28px;
  align-items: start;

  @media (${device.desktop}) {
    margin-top: 100px;
  }
`

export const Copyright = styled.div`
  margin-top: 30px;
  grid-row: 2;
  grid-column: 1 / 7;

  a {
    color: inherit;
    text-decoration: none;
    margin-right: 10px;

    &:hover {
      text-decoration: underline;
    }
  }
  @media (${device.desktop}) {
    margin-top: 0;
    grid-row: 1;
    grid-column: 2 / 9;
    br {
      display: none;
    }

    span,
    a {
      display: inline-block;
      white-space: nowrap;
      margin-right: 0;

      :not(:last-child) {
        margin-right: 32px;
      }
    }
  }
`

export const OtherContainer = styled.div`
  grid-row: 1;
  grid-column: 1 / 7;

  @media (${device.desktop}) {
    grid-column: 7 / 13;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-left: auto;
  }
`

export const LanguageSelector = styled.select`
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: inherit;
  text-decoration-line: underline;
  cursor: pointer;
  margin-bottom: 35px;
  margin-top: 15px;
  display: inline;

  border: none;
  outline: none;
  background: none;
  appearance: none;

  @media (${device.desktop}) {
    margin: 0;
  }
`

export const BackToTop = styled.div`
  display: none;
  color: inherit;

  @media (${device.desktop}) {
    display: inline;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-left: 20px;
    white-space: nowrap;
    cursor: pointer;
    svg {
      transform: rotate(-90deg);
      margin-right: 8px;
      path {
        fill: currentColor;
      }
    }
  }
`
