import { Normalize } from "styled-normalize"
import React, { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Global from "../theme/Global"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Menu from "../components/menu"
import PageWrapperContext from "../context/PageWrapperContext"
import SearchModal from "../components/SearchModal"
import SearchContext from "../context/SearchContext"
import ScrollLock from "react-scrolllock"
import useDebounce from "../hooks/useDebounce"
import { DomainContext } from "../context/DomainContext"
import { DomainAwareThemeProvider } from "../components/DomainAwareThemeProvider"

const BaseLayout = props => {
  const [headerCollapsed, setHeaderCollapsed] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setHeaderCollapsed((window.scrollY || window.pageYOffset) > 80)
    }
    window.addEventListener("scroll", onScroll, false)
    return () => window.removeEventListener("scroll", onScroll, false)
  })

  const [menuOpen, setMenuOpen] = useState(false)
  const [isSearching, setSearching] = useState(false)

  const scrollLocked = useDebounce(menuOpen, 150)

  const {
    locale,
    theme,
    headerCollapsedTheme,
    headerTheme,
    fixedHeader,
    path,
    decorated,
  } = React.useContext(PageWrapperContext)

  useEffect(() => setMenuOpen(false), [path])

  const [bannerSize, setBannerSize] = useState(0)

  const mainDomain = useContext(DomainContext).isCurrentDefault

  return (
    <SearchContext.Provider
      value={{
        ...SearchContext.defaultValue,
        openSearchModal: () => setSearching(true),
      }}
    >
      <Normalize />
      <Helmet htmlAttributes={{ lang: locale }}>
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;500;700&family=Poppins:wght@300;500;700&display=swap"
          rel="stylesheet"
          media="all"
        />
      </Helmet>
      <Global theme={theme} />
      <DomainAwareThemeProvider theme={theme}>
        {decorated && (
          <Header
            locale={locale}
            search={() => setSearching(true)}
            fixed={fixedHeader}
            theme={headerTheme}
            collapsedTheme={headerCollapsedTheme}
            collapsed={!menuOpen && headerCollapsed}
            menuOpen={scrollLocked || menuOpen}
            onMenuClick={() => setMenuOpen(s => !s)}
            onBannerSizeChange={size => setBannerSize(size)}
          />
        )}
        <Menu open={menuOpen} setOpen={setMenuOpen} locale={locale} />
        <ScrollLock isActive={scrollLocked || menuOpen} accountForScrollbars />
        {mainDomain && (
          <SearchModal
            visible={isSearching}
            onClose={() => setSearching(false)}
            locale={locale}
          />
        )}
        <main
          style={{
            paddingTop: bannerSize,
            transition: "padding-top 70ms ease-out",
          }}
        >
          {props.children}
        </main>

        {decorated && <Footer locale={locale} />}
      </DomainAwareThemeProvider>
    </SearchContext.Provider>
  )
}

export default BaseLayout
