import styled from "styled-components"

export const IconButton = styled.button`
  width: 32px;
  min-width: 32px;
  height: 32px;
  background: ${props => !props.hideBackground && "white"};
  border: none;
  outline: none;
  border-radius: 50%;
  transition: all 0.3s ease-out;
  padding: 0;

  &:hover {
    opacity: 0.85;
  }
`

export const IconButtonWithShadow = styled(IconButton)`
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.1);
  }
`

export const IconButtonNoBackground = styled(IconButton)`
  background: transparent;

  > svg > path {
    fill: ${props => props.theme.color} !important;
  }
`
